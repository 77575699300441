<template>
    <v-app>
        <v-app-bar fixed clipped-left app light elevation="0" class="app-bar">
            <div class="d-flex align-center">
                <v-img
                    @click="$router.push({ name: 'home' })"
                    :alt="$store.state.appName"
                    class="shrink mr-2"
                    contain
                    :src="$store.state.logo"
                    transition="scale-transition"
                    width="150"
                />
            </div>

            <v-divider class="mr-5" vertical></v-divider>

            <v-btn target="_blank" text @click="locationDialog = true">
                <v-icon color="grey lighten-1"> mdi-map-marker-outline</v-icon>
                <span class="mr-2">
          {{
                        shortLocation ? shortLocation.substr(0, 20) : "Select Location"
                    }}...
        </span>
            </v-btn>

            <v-spacer></v-spacer>

            <v-autocomplete
                clearable
                hide-details
                hide-selected
                append-icon="mdi-magnify"
                item-text="Description"
                item-value="name"
                label="Search anything here..."
                solo
                return-object
                v-model="searchSelected"
                :items="productItems"
                :loading="isLoading"
                :search-input.sync="globalSearch"
                @change="searchProduct"
                @click:append="searchProduct"
            >
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                        <v-list-item-avatar>
                            <img :src="data.item.meta_image_file.url">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.product_name"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.product_unit"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </template>

                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Search for
                            <strong>Search for products..</strong>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>

            <v-spacer></v-spacer>

            <v-btn
                v-if="!this.$store.getters.loggedIn"
                color="orange"
                class="mr-2"
                dark
                @click.stop="loginDialog = !loginDialog"
            >
                <v-icon color="white lighten-1"> mdi-account-circle</v-icon>
                <span class="mr-2">Login/Sign Up</span>
            </v-btn>

            <v-menu offset-y v-else>
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                        color="orange"
                        elevation="0"
                        class="white--text ma-5"
                        v-bind="attrs"
                        v-on="on"
                    >
                        My Profile
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item link two-line :to="{ name: 'my-profile' }">
                        <v-list-item-content>
                            <v-list-item-title> My Account</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ this.$store.state.mobile }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link :to="{ name: 'my-order' }">
                        <v-list-item-content>
                            <v-list-item-title>My Orders</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item link :to="{ name: 'Wishlist' }">
                      <v-list-item-content>
                        <v-list-item-title>Wishlist</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item link :to="{ name: 'my-address' }">
                        <v-list-item-content>
                            <v-list-item-title>Saved Address</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item link>
                            <v-list-item-content>

                      <v-list-item-title>FAQ's</v-list-item-title>
                      </v-list-item-content>

                    </v-list-item> -->
                    <v-list-item link @click="logout">
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-divider vertical></v-divider>

            <v-btn
                class="ml-2"
                target="_blank"
                text
                @click.stop="cartDrawer = !cartDrawer"
            >
                <v-icon color="grey lighten-1"> mdi-cart</v-icon>
                {{
                    cart.count > 0 ? cart.count + " items | ₹ " + cart.total : "My Cart"
                }}
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>
        <notifications position="top center" width="500" closeOnClick/>

        <v-footer class="footer-session pa-0">
            <v-card flat tile width="100%">
                <v-container>
                    <v-card-text class="pt-10 pb-5">
                        <v-row>
                            <v-col cols="12" md="3" lg="3" sm="12">
                                <v-img
                                    :alt="$store.state.storeInformation.store_name"
                                    :src="$store.state.storeInformation.logo_file.url"
                                    width="180"
                                />
                                <div class="mt-5 underline-a">
                                    <p>
                                        <v-icon>mdi-phone-outline</v-icon>
                                        <a
                                            target="_blank"
                                            :href="
                        'tel:+' +
                        $store.state.storeInformation.country_code +
                        $store.state.storeInformation.mobile
                      "
                                        >
                                            +{{
                                                $store.state.storeInformation.country_code +
                                                "" +
                                                $store.state.storeInformation.mobile
                                            }}
                                        </a>
                                    </p>

                                    <p>
                                        <v-icon>mdi-email</v-icon>
                                        <a
                                            target="_blank"
                                            :href="'mailto:' + $store.state.storeInformation.email"
                                        >
                                            {{ $store.state.storeInformation.email }}
                                        </a>
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" lg="3" sm="12">
                                <div class="underline-a">
                                    <h3 class="mb-5">CATEGORIES</h3>
                                    <p><a href="/grocery">Vegetables</a></p>
                                    <p><a href="/grocery">Grocery & Staples</a></p>
                                    <p><a href="/grocery">Soft Drinks</a></p>
                                    <p><a href="/grocery">Biscuits & Cookies</a></p>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" lg="3" sm="12">
                                <div class="underline-a">
                                    <h3 class="mb-5">ABOUT US</h3>
                                    <p><a href="/contact-us">Contact Us</a></p>
                                    <p><a href="/my-profile">About Us</a></p>
                                </div>
                            </v-col>
                            <v-col class="icon-f underline-a" cols="12" md="3" lg="3" sm="12">
                                <h3 class="mb-4">DOWNLOAD APP</h3>

                                <img
                                    class="mr-2"
                                    src="assets/img/google.png"
                                    height="45"
                                    width="120"
                                />
                                <img src="assets/img/apple.png" height="45" width="120"/>

                                <h3 class="mb-2 mt-5">GET IN TOUCH</h3>
                                <a href="#">
                                    <v-icon> mdi-facebook</v-icon>
                                </a>

                                <a href="#">
                                    <v-icon> mdi-twitter</v-icon>
                                </a>
                                <a href="#">
                                    <v-icon> mdi-instagram</v-icon>
                                </a>
                                <a href="#">
                                    <v-icon> mdi-facebook-messenger</v-icon>
                                </a>
                                <a href="#">
                                    <v-icon> mdi-google</v-icon>
                                </a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>

                    <!--          third footer content-->

                    <v-card-text>
                        <v-row>
                            <h4>
                                © Copyright
                                {{
                                    new Date().getFullYear() +
                                    " " +
                                    $store.state.storeInformation.store_name
                                }}. All Rights Reserved
                            </h4>
                            <v-spacer></v-spacer>
                            <!-- <img src="assets/img/payment_methods.png" height="40" /> -->
                        </v-row>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-footer>

        <!-- My Cart -->
        <v-navigation-drawer
            v-model="cartDrawer"
            light
            color="#eee"
            app
            temporary
            right
            width="400"
        >
            <v-card>
                <v-card-title class="font-weight-black">
                    My Cart
                    <v-spacer></v-spacer>
                    <v-icon @click.stop="drawer = !drawer" class="font-weight-black"
                    >mdi-close
                    </v-icon
                    >
                </v-card-title>
                <v-card-subtitle>{{ cart.count }} Items</v-card-subtitle>
            </v-card>

            <v-row class="ma-2">
                <h6>shipment 1 of 1</h6>
                <v-spacer></v-spacer>
                <h6>{{ cart.count }} items</h6>
            </v-row>

            <v-row dense>
                <v-col
                    cols="12"
                    v-for="(item, i) in cart.items"
                    :key="i"
                    class="ma-0 pa-0"
                >
                    <CartProductComponent
                        :product="item.product"
                        :quantity="item.quantity"
                        :sku="item.sku"
                        :price="item.price"
                        :product_variant_id="item.product_variant_id"
                    />
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12">
                    <CartBillComponent :product="cart.items"/>
                </v-col>
            </v-row>

            <template v-slot:append>
                <v-card class="ma-1 pa-2">
                    <v-card-text v-if="$store.state.deliveringAddress">
                        <v-list-item>
                            <template v-slot:default="{ active }">
                                <v-list-item-icon>
                                    <v-icon> mdi-home</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        delivering to
                                        {{ $store.state.deliveringAddress.contact_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{
                                            $store.state.deliveringAddress.address1 +
                                            " " +
                                            $store.state.deliveringAddress.address2
                                        }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn
                                        @click="changeAddressDrawer()"
                                        color="orange"
                                        x-small
                                        text
                                    >
                                        change
                                    </v-btn>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </v-card-text>
                    <v-btn
                        ml="2"
                        mr="2"
                        block
                        dark
                        elevation="1"
                        large
                        color="orange"
                        @click="checkLoginCart"
                    >
                        <v-icon> mdi-cart</v-icon>
                        {{
                            cart.count > 0
                                ? cart.count + " items | ₹ " + cart.total
                                : "Add Items To Cart"
                        }}
                    </v-btn>
                </v-card>
            </template>
        </v-navigation-drawer>
        <!-- End My Cart -->

        <!-- My Address -->
        <v-navigation-drawer
            v-model="addressDrawer"
            light
            app
            temporary
            right
            width="400"
        >
            <v-card>
                <v-card-title class="font-weight-black">
                    <v-icon @click="changeAddressDrawer()" class="font-weight-black">
                        mdi-keyboard-backspace
                    </v-icon>
                    &nbsp; My Address
                </v-card-title>
            </v-card>

            <v-divider></v-divider>

            <v-card>
                <v-list-item>
                    <v-row class="pa-5">
                        <v-col cols="12" md="2">
                            <v-icon large color="orange" @click="addAddressDialogFunction">mdi-plus-circle-outline
                            </v-icon>
                        </v-col>
                        <v-col cols="12" md="9" @click="addAddressDialogFunction">
                            <h4 class="mb-0 h5">Add a new address in</h4>
                            <p class="mt-0 mb-0 caption">
                                {{ shortLocation }}
                            </p>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-card>
            <v-divider></v-divider>
            <v-list-item class="body-clr ml-2">
                <p class="small ml-5 mt-5 mb-5 caption">Choose Delivery Address</p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group>
                <v-radio-group v-model="deliveryAddress">
                    <v-list-item
                        v-for="(address, index) in $store.state.addresses"
                        :key="index"
                    >
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-radio
                                    @click="deliveryAddressSelect(address)"
                                    :value="address.id"
                                ></v-radio>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                        address.address_name
                                    }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{
                                        address.formatted_address
                                            ? address.formatted_address
                                            : address.address1 +
                                            " " +
                                            address.address2 +
                                            " " +
                                            address.locality
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-radio-group>
            </v-list-item-group>
            <v-divider></v-divider>
            <template v-slot:append>
                <v-card>
                    <v-btn
                        @click="changeAddressDrawer()"
                        block
                        elevation="2"
                        dark
                        x-large
                        color="orange"
                        mr-2
                        ml-2
                    >
                        Done
                    </v-btn>
                </v-card>
            </template>
        </v-navigation-drawer>
        <!-- End My Address -->

        <!--popup sign in-->
        <v-dialog v-model="loginDialog" max-width="500px">
            <v-card>
                <v-card-title class="justify-center">
          <span
              class="text-h5 font-weight-light"
              v-show="loginScreen == 'Login'"
          >Login to your account
          </span>
                    <span
                        class="text-h5 font-weight-light"
                        v-show="loginScreen == 'Register'"
                    >Register your account !!
          </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitLogin"
                            ref="form"
                            v-show="loginScreen == 'Login'"
                        >
                            <v-row>
                                <v-col cols="12" class="justify-center">
                                    <v-card-text
                                    >Enter your phone number to Login/Sign up
                                    </v-card-text
                                    >
                                </v-col>

                                <v-col cols="12">
                                    <VuePhoneNumberInput
                                        v-model="mobile"
                                        valid-color="#666666"
                                        default-country-code="IN"
                                        required
                                        v-on:update="getMobileData"
                                    />
                                </v-col>
                                <v-col cols="12" v-show="showOtp">
                                    <v-otp-input
                                        v-model="form.otp"
                                        :disabled="loading"
                                        @finish="loginUser"
                                    ></v-otp-input>
                                    <v-overlay absolute :value="loading">
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-col>

                                <v-col cols="12" class="justify-center" v-show="!showOtp">
                                    <v-btn
                                        depressed
                                        block
                                        color="orange"
                                        dark
                                        class="otpBtn"
                                        @click="checkUser"
                                    >
                                        Check User
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" class="justify-center" v-show="showOtp">
                                    <v-btn
                                        depressed
                                        block
                                        color="orange"
                                        dark
                                        class="otpBtn"
                                        @click="loginUser"
                                    >
                                        Login
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--popup close-->

        <!-- popup location -->
        <v-dialog
            class="dialog-content"
            v-model="locationDialog"
            max-width="530"
        >
            <v-card class="pa-5">
                <v-row>
                    <v-col cols="12" md="11">
                        <v-card-title class="text-h8"> Change Location</v-card-title>
                    </v-col>
                    <v-col cols="12" md="1">
                        <v-icon @click="checkSelectedLocation()">mdi-close</v-icon>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-row>
                        <v-btn
                            class="mr-4"
                            color="green darken-1"
                            dark
                            @click="getGeoLocate"
                        >
                            Delect my location
                        </v-btn>

                        <v-btn class="mr-4" small text outlined color="#9d9d9d" fab>
                            OR
                        </v-btn>

                        <vuetify-google-autocomplete
                            label="Area / Locality"
                            outlined
                            ref="address"
                            id="map"
                            country="in"
                            :types="types"
                            v-on:placechanged="getAddressData"
                            v-model="locationForm.locality"
                        >
                        </vuetify-google-autocomplete>

                        <vue-google-autocomplete
                            v-show="false"
                            ref="address"
                            id="map"
                            placeholder="Please type your address"
                            country="in"
                            :types="types"
                            v-on:placechanged="getAddressData"
                            v-model="locationForm.locality"
                        >
                        </vue-google-autocomplete>
                    </v-row>
                </v-card-actions>

                <!---address section--->

                <div class="mb-2 pr-2 scroll" v-if="$store.state.addresses">
                    <p>Saved Address</p>
                    <!--address1--->
                    <v-card
                        v-for="(address, index) in $store.state.addresses"
                        :key="index"
                        outlined
                        class="pt-2 pr-2 pl-4"
                        flat
                        color="#f7f7f7"
                    >
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-icon large>mdi-map-marker-outline</v-icon>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="mb-1">
                                    <strong>{{ address.address_name }}</strong>
                                </p>
                                <p class="text-light mt-0">
                                    {{
                                        address.formatted_address
                                            ? address.formatted_address
                                            : address.address1 +
                                            " " +
                                            address.address2 +
                                            " " +
                                            address.locality
                                    }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-card>
        </v-dialog>
        <!-- end popup location  -->

        <!--popup sign in-->
        <v-dialog v-model="addAddressDialog" max-width="50%">
            <v-card :loading="loading">
                <v-card-title class="justify-center">
                    <h4>Add New Delivery Address</h4>
                </v-card-title>
                <v-row class="justify-center">
                    <v-card-subtitle>
                        <h5 class="subtitle-1 ma-3">
                            Please enter the accurate address, it will help us to serve you
                            better
                        </h5>
                    </v-card-subtitle>
                </v-row>

                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitCustomerAddress"
                            ref="addAddressForm"
                        >
                            <v-row class="justify-center">
                                <v-col cols="8">
                                    <vuetify-google-autocomplete
                                        label="Area / Locality"
                                        outlined
                                        ref="address"
                                        id="map"
                                        country="in"
                                        :types="types"
                                        v-on:placechanged="getAddressData"
                                        v-model="addAddressForm.locality"
                                    >
                                    </vuetify-google-autocomplete>

                                    <vue-google-autocomplete
                                        v-show="false"
                                        ref="address"
                                        id="map"
                                        placeholder="Please type your address"
                                        country="in"
                                        :types="types"
                                        v-on:placechanged="getAddressData"
                                        v-model="addAddressForm.locality"
                                    >
                                    </vue-google-autocomplete>
                                </v-col>
                                <v-col cols="4" class="">
                                    <v-btn small outlined @click="getGeoLocate">
                                        <v-icon small>mdi-navigation-variant</v-icon>
                                        Detect
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <v-text-field
                                        outlined
                                        v-model="addAddressForm.contact_name"
                                        label="Contact Person Name"
                                        :rules="rules.contact_name"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="pb-0">
                                    <v-text-field
                                        outlined
                                        v-model="addAddressForm.address1"
                                        label="Flat / House / Office No."
                                        :rules="rules.address1"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="pb-0">
                                    <v-text-field
                                        outlined
                                        v-model="addAddressForm.address2"
                                        label="Street / Society / Office Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" class="pb-0">
                                    <v-select
                                        outlined
                                        v-model="addAddressForm.address_name"
                                        :items="addressNameItems"
                                        :rules="rules.address_name"
                                        label="Select"
                                        required
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0" v-show="addressNameOther">
                                <v-col cols="12" class="pb-0">
                                    <v-text-field
                                        outlined
                                        v-model="addAddressForm.other_address_name"
                                        label="Enter Address Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-btn
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                depressed
                                block
                                color="orange"
                                dark
                            >
                                Save Details
                            </v-btn>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--popup close-->
    </v-app>
</template>

<script>
import CustomerAuth from "@/apis/CustomerAuth";
import CartProductComponent from "@/components/CartProductComponent";
import CartBillComponent from "@/components/CartBillComponent";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Store from "@/apis/Store";
import CustomerAddress from "@/apis/CustomerAddress";
import Product from "@/apis/Product";

export default {
    name: "App",

    components: {
        CartProductComponent,
        CartBillComponent,
        VuePhoneNumberInput,
        VueGoogleAutocomplete,
    },

    created() {
        if (!this.$store.state.storeInformation) {
            this.getStoreInformation();
        }
    },

    mounted() {
        this.cart = this.$store.state.cart;
        this.form.store_id = this.$store.state.storeInformation.id;

        this.checkSelectedLocation();

        if (this.$store.state.location)
            this.shortLocation = this.$store.state.location.formatted_address;

        if (this.$store.state.customerLogin)
            this.customerAddressForm.customer_id = this.$store.state.customerId;
    },

    data: () => ({
        cart: {
            count: 0,
            total: 0,
            chargeableDeliveryCost: 0,
            amtRequiredForFreeDelivery: 0,
            items: {},
            promoInfo: [],
            paymentMode: null,
            step: ["cart"],
            uniqueSkuInCart: 0,
        },
        city: ["delhi", "Bareilly", "mumbai", "gurugram"],
        loginDialog: false,
        locationDialog: false,
        cartDrawer: null,
        addressDrawer: null,
        addAddressDialog: false,

        showOtp: false,
        valid: false,
        passwordRules: [(v) => !!v || "Password is required"],
        loading: false,
        mobile: "",
        form: {
            mobile: "",
            country_code: "",
            otp: "",
            store_id: "",
        },

        loginScreen: "Login",

        customerAddressForm: {
            customer_id: "",
        },
        enableGeolocation: false,
        types: "(regions)",
        geolocationOption: {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        },
        address: "",
        geolocation: {
            geocoder: null,
            loc: null,
            position: null,
        },

        locationForm: {
            locality: "",
        },

        shortLocation: null,
        deliveryAddress: "",

        addAddressForm: {
            id: "",
            customer_id: null,
            store_id: null,
            contact_name: "",
            address_name: "",
            other_address_name: "",
            address1: "",
            address2: "",
            locality: "",
            landmark: "",
            latitude: null,
            longitude: null,
            type: "Shipping",
        },
        rules: {
            customer_id: [(v) => !!v || "Customer Id is required"],
            store_id: [(v) => !!v || "Store ID is required"],
            contact_name: [(v) => !!v || "Contact Name is required"],
            address_name: [(v) => !!v || "Address Name is required"],
            address1: [(v) => !!v || "Address1 is required"],
            locality: [(v) => !!v || "Locality is required"],
            type: [(v) => !!v || "Type is required"],
        },
        addressNameItems: ["Home", "Office", "Others"],
        addressNameOther: false,

        isLoading: false,
        globalSearch: null,
        globalDescriptionLimit: 60,
        entriesGlobal: [],
        searchSelected: [],
    }),

    computed: {
        productItems() {
            return this.entriesGlobal.map(entry => {
                const GlobalDescription = entry.Description.length > this.globalDescriptionLimit
                    ? entry.Description.slice(0, this.globalDescriptionLimit) + '...'
                    : entry.Description

                return Object.assign({}, entry, {GlobalDescription})
            })
        },
    },

    methods: {
        checkLoginCart: function () {
            if (this.$store.getters.loggedIn) {
                if (this.$store.state.deliveringAddress)
                    this.$router.push({name: "checkout"});
                else {
                    if (this.cart.count == 0) {
                        this.cartDrawer = false;
                    } else {
                        this.cartDrawer = false;
                        this.addressDrawer = true;
                    }
                }
            } else {
                this.drawer = false;
                this.loginDialog = true;
            }
        },

        changeAddressDrawer: function () {
            this.cartDrawer = !this.cartDrawer;
            this.addressDrawer = !this.addressDrawer;
        },

        getMobileData(val) {
            this.form.country_code = val.countryCallingCode;
            this.form.mobile = val.nationalNumber;
        },

        loginUser() {
            this.loading = true;

            if (this.$refs.form.validate()) {
                CustomerAuth.login(this.form)
                    .then((response) => {
                        // console.log(response);
                        this.loading = false;
                        this.token = response.data.data.accessToken;

                        var localItem = {
                            access_token: this.token,
                            customerId: response.data.data.id,
                            name: response.data.data.name,
                            email: response.data.data.email,
                            country_code: response.data.data.country_code,
                            mobile: response.data.data.mobile,
                            profile: response.data.data.profile,
                            type: response.data.data.type,
                            created_at: response.data.data.created_at,
                        };

                        localStorage.setItem("customerLogin", JSON.stringify(localItem));
                        localStorage.setItem(
                            "addresses",
                            JSON.stringify(response.data.data.addresses)
                        );

                        this.$store.state.token = this.token;
                        this.$store.state.customerId = response.data.data.id;
                        this.$store.state.name = response.data.data.name;
                        this.$store.state.email = response.data.data.email;
                        this.$store.state.country_code = response.data.data.country_code;
                        this.$store.state.mobile = response.data.data.mobile;
                        this.$store.state.profile = response.data.data.profile;
                        this.$store.state.type = response.data.data.type;
                        this.$store.state.created_at = response.data.data.created_at;
                        this.$store.state.customerLogin = JSON.stringify(localItem);
                        this.$store.state.addresses = JSON.stringify(
                            response.data.data.addresses
                        );

                        location.reload();
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        checkUser: function () {
            this.loading = true;
            if (this.$refs.form.validate()) {
                CustomerAuth.checkUser(this.form)
                    .then(() => {
                        this.loading = false;
                        this.showOtp = true;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        console.log(text);
                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        logout() {
            CustomerAuth.logout().then(() => {
                localStorage.removeItem("customerLogin");
                localStorage.removeItem("addresses");
                localStorage.removeItem("cart");
                this.$router.push("/");

                location.reload();
            });
        },

        checkSelectedLocation() {
            /*if (!this.$store.state.location) {
                this.locationDialog = true;
            } else {
                this.locationDialog = false;
            }*/
            this.locationDialog = false;
        },

        getGeoLocate() {
            this.types = "sublocality";
            this.$refs.address.geolocate();
        },

        getAddressData: function (addressData, placeResultData, id) {
            this.locality = placeResultData.formatted_address;

            var localItem = {
                formatted_address: placeResultData.formatted_address,
                latitude: addressData.latitude,
                longitude: addressData.longitude,
                place_id: placeResultData.place_id,
            };

            localStorage.setItem("location", JSON.stringify(localItem));

            this.$store.state.location = JSON.stringify(localItem);
            this.shortLocation = placeResultData.formatted_address;

            this.locationDialog = false;

            this.addAddressForm.latitude = addressData.latitude;
            this.addAddressForm.longitude = addressData.longitude;
            this.addAddressForm.locality = placeResultData.formatted_address;
        },

        deliveryAddressSelect: function (address) {
            localStorage.setItem("deliveringAddress", JSON.stringify(address));
            this.$store.state.deliveringAddress = address;
        },

        getStoreInformation() {
            Store.store_information({
                host: window.location.host
            })
                .then((response) => {
                    this.loading = false;
                    this.$store.state.storeInformation = response.data.data;
                    localStorage.setItem("storeInformation", JSON.stringify(response.data.data));
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    console.log(text);
                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        submitCustomerAddress() {
            if (this.$refs.addAddressForm.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.addAddressForm) {
                    fd.append(element, this.addAddressForm[element]);
                }

                if (this.addAddressForm.id) {
                    fd.append("_method", "PATCH");
                }

                CustomerAddress.store(fd, this.addAddressForm.id)
                    .then((response) => {
                        this.$refs.addAddressForm.reset();

                        this.loading = false;

                        this.$notify({
                            title: "Success",
                            text: "Address Information Saved !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.addAddressDialog = false;

                        this.getCustomerAddressData();

                        this.saveCustomerAddressData();
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        getCustomerAddressData() {
            CustomerAddress.index(this.customerAddressForm)
                .then((response) => {
                    this.customerAddressData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        saveCustomerAddressData() {
            CustomerAddress.index({
                customer_id: this.customerAddressForm.customer_id,
                type: "storage",
            })
                .then((response) => {
                    localStorage.setItem("addresses", JSON.stringify(response.data.data));
                    this.$store.state.addresses = JSON.stringify(response.data.data);

                    location.reload();
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        editCustomerAddress(address) {
            this.addAddressForm.id = address.id;
            this.addAddressForm.contact_name = address.contact_name;
            this.addAddressForm.address_name = address.address_name;
            this.addAddressForm.other_address_name = address.other_address_name;
            this.addAddressForm.address1 = address.address1;
            this.addAddressForm.address2 = address.address2;
            this.addAddressForm.locality = address.locality;
            this.addAddressForm.latitude = address.latitude;
            this.addAddressForm.longitude = address.longitude;
            this.addAddressForm.landmark = address.landmark;

            this.addAddressDialog = true;
        },

        addAddressDialogFunction() {
            this.addressDrawer = false;
            this.addAddressDialog = true;
            this.addAddressForm.customer_id = this.$store.state.customerId;
            this.addAddressForm.store_id = this.$store.state.storeInformation.id;

            this.addAddressForm.latitude = this.$store.state.location.latitude;
            this.addAddressForm.longitude = this.$store.state.location.longitude;
            this.addAddressForm.locality = this.$store.state.location.formatted_address;
        },

        searchProduct() {
            if (this.searchSelected)
                this.$router.push("/product/" + this.searchSelected.product_slug + "/" + this.searchSelected.id);
        }
    },

    watch: {
        "addAddressForm.address_name": function (val) {
            if (val == "Others") this.addressNameOther = true;
            else this.addressNameOther = false;
        },

        globalSearch(val) {
            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            Product.index({
                search: val,
                store_id: this.form.store_id
            })
                .then((response) => {
                    this.countGlobal = response.data.meta.total;
                    this.entriesGlobal = response.data.data;
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => (this.isLoading = false))
        },
    },
};
</script>
