<template>
  <div class="body-clr">
    <v-container class="pt-10 pb-10">
      <v-row class="mt-5 mb-5">
        <v-col cols="2"></v-col>
        <v-col cols="8" class="border">
          <v-row class="white-bg">
            <v-col cols="4">
              <ProfileLeftNavComponent />
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="8" class="pr-7 pl-7">
              <h2>Wishlist</h2>
              <v-row class="mt-5">
                <v-col cols="6">
                  <v-card class="pa-2">
                    <v-chip class="mt-4 ml-4" outlined color="green"
                      >15% OFF
                    </v-chip>

                    <v-img
                      class="pa-10"
                      height="150"
                      max-width="260"
                      src="../../public/assets/img/item/3.jpg"
                    ></v-img>

                    <v-card-title>Product Title Here</v-card-title>
                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :value="4.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.5 (413)</div>
                      </v-row>

                      <div class="my-4 text-subtitle-1">
                        Available in - 500 gm
                      </div>
                      <v-row align="center" class="mx-0">
                        <h3 class="">
                          <b>Rs- 45</b>
                          <v-icon> mdi-tag-outline </v-icon>
                        </h3>

                        <v-card-actions>
                          <v-btn
                            class="pa-4"
                            rounded
                            x-small
                            color="warning"
                            onclick="addtocard"
                          >
                            <v-icon color="white lighten-1"> mdi-cart </v-icon>
                            <b> Add To Cart</b>
                          </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="pa-2">
                    <v-chip class="mt-4 ml-4" outlined color="green"
                      >15% OFF
                    </v-chip>

                    <v-img
                      class="pa-10"
                      height="150"
                      max-width="260"
                      src="../../public/assets/img/item/4.jpg"
                    ></v-img>

                    <v-card-title>Product Title Here</v-card-title>
                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :value="4.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.5 (413)</div>
                      </v-row>

                      <div class="my-4 text-subtitle-1">
                        Available in - 500 gm
                      </div>
                      <v-row align="center" class="mx-0">
                        <h3 class="">
                          <b>Rs- 45</b>
                          <v-icon> mdi-tag-outline </v-icon>
                        </h3>

                        <v-card-actions>
                          <v-btn
                            class="pa-4"
                            rounded
                            x-small
                            color="warning"
                            onclick="addtocard"
                          >
                            <v-icon color="white lighten-1"> mdi-cart </v-icon>
                            <b> Add To Cart</b>
                          </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="pa-2">
                    <v-chip class="mt-4 ml-4" outlined color="green"
                      >15% OFF
                    </v-chip>

                    <v-img
                      class="pa-10"
                      height="150"
                      max-width="260"
                      src="../../public/assets/img/item/4.jpg"
                    ></v-img>

                    <v-card-title>Product Title Here</v-card-title>
                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :value="4.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.5 (413)</div>
                      </v-row>

                      <div class="my-4 text-subtitle-1">
                        Available in - 500 gm
                      </div>
                      <v-row align="center" class="mx-0">
                        <h3 class="">
                          <b>Rs- 45</b>
                          <v-icon> mdi-tag-outline </v-icon>
                        </h3>

                        <v-card-actions>
                          <v-btn
                            class="pa-4"
                            rounded
                            x-small
                            color="warning"
                            onclick="addtocard"
                          >
                            <v-icon color="white lighten-1"> mdi-cart </v-icon>
                            <b> Add To Cart</b>
                          </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="pa-2">
                    <v-chip class="mt-4 ml-4" outlined color="green"
                      >15% OFF
                    </v-chip>

                    <v-img
                      class="pa-10"
                      height="150"
                      max-width="260"
                      src="../../public/assets/img/item/4.jpg"
                    ></v-img>

                    <v-card-title>Product Title Here</v-card-title>
                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :value="4.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.5 (413)</div>
                      </v-row>

                      <div class="my-4 text-subtitle-1">
                        Available in - 500 gm
                      </div>
                      <v-row align="center" class="mx-0">
                        <h3 class="">
                          <b>Rs- 45</b>
                          <v-icon> mdi-tag-outline </v-icon>
                        </h3>

                        <v-card-actions>
                          <v-btn
                            class="pa-4"
                            rounded
                            x-small
                            color="warning"
                            onclick="addtocard"
                          >
                            <v-icon color="white lighten-1"> mdi-cart </v-icon>
                            <b> Add To Cart</b>
                          </v-btn>
                        </v-card-actions>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ProfileLeftNavComponent from "../components/ProfileLeftNavComponent";

export default {
  name: "Wishlist",

  components: {
    ProfileLeftNavComponent,
  },

  data: () => ({
    items: ["xyz", "abc", "ABC", "XYZ"],
  }),
};
</script>
