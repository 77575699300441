<template>
    <div class="body-clr">
        <v-container class="pt-10 pb-10">
            <v-row class="mt-5 mb-5">
                <v-col cols="2"></v-col>
                <v-col cols="8" class="border">
                    <v-row class="white-bg">
                        <v-col cols="4">
                            <ProfileLeftNavComponent/>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="8" class="pr-7 pl-7">
                            <h2>Order List</h2>
                            <v-row class="mt-5">
                                <v-data-table
                                    :headers="headers"
                                    :items="orderData.data"
                                    :options.sync="options"
                                    :server-items-length="total"
                                    :loading="loading"
                                ></v-data-table>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import ProfileLeftNavComponent from "../components/ProfileLeftNavComponent";
import Orders from "@/apis/Orders";

export default {
    name: "MyOrder",

    components: {
        ProfileLeftNavComponent,
    },

    data: () => ({
        orderData: [],
        loading: false,
        headers: [
            {
                text: "Order ",
                align: "start",
                sortable: false,
                value: "order_code",
            },
            {text: "Delivery Status", value: "delivery_status"},
            {text: "Payment Status", value: "payment_status"},
            {text: "Total", value: "price"},
            {text: "Date Purchased", value: "created_at"},
        ],

        form: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            customer_id: "",
        },
        options: {},
        total: 0,

    }),

    mounted() {
        this.form.customer_id = this.$store.state.customerId;
    },

    methods: {
        getOrderData: function () {
            Orders.index(this.form)
                .then((response) => {
                    this.orderData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getOrderData();
            },
            deep: true,
        },
    },
};
</script>
