<template>
  <v-card white>
    <div>
      <v-card-title>Bill Details</v-card-title>

      <v-card-text>
        <div class="d-flex justify-space-between">
          <span>MRP</span>
          <span>₹ {{ this.$store.state.cart.total }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <strong>MRP</strong>
          <strong>₹ {{ this.$store.state.cart.total }}</strong>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>


<script>
export default {
  name: "CartBillComponent",

  props: ["product"],

  data: () => ({}),

  created() {
    this;
  },

  methods: {},
};
</script>
