import axios from "axios";
import store from "../store/index";

let Api = axios.create({
    // baseURL: "http://localhost:8000/api",
    baseURL: "https://api.essenmart.com/api",
});

Api.defaults.withCredentials = true;

if (store.state.token)
    Api.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token;

Api.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status == 401) {
        localStorage.removeItem("customerLogin");
        location.href = '/';
    }
    return Promise.reject(error)
})

export default Api;
