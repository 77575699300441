import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/customer-address", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/customer-address/" + id, form);
    },

    async show(id) {
        return Api.post("/customer-address/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/customer-address/" + id, form);
    },

    async delete(id) {
        return Api.delete("/customer-address/" + id);
    },

};
