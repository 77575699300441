import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/store", {
            params: form
        });
    },

    async store(form) {
        return Api.post("/store", form);
    },

    async show(id) {
        return Api.post("/store/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/store/" + id, form);
    },

    async store_information(form) {
        return Api.post("/store-information", form);
    },

    async changeStatus(id) {
        return Api.post("/store-status/" + id);
    },

    async updateTax(form, id) {
        return Api.patch("/store-tax/" + id, form);
    },

    async delete(id) {
        return Api.delete("/store/" + id);
    },
};
