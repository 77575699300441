import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/customer", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/customer/" + id, form);
    },

    async show(id) {
        return Api.post("/customer/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/customer/" + id, form);
    },

};
