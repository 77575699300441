import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/product", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/product/" + id, form);
    },

    async show(id) {
        return Api.post("/product/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/product/" + id, form);
    },

    async changeStatus(id) {
        return Api.post("/product-status/" + id);
    },

    async delete(id) {
        return Api.delete("/product/" + id);
    },
};
