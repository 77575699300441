<template>
  <div class="about">
    <v-row class="bg-dark">
      <v-container class="mt-5 mb-5">
        <v-col class="text-center"
               cols="12">
          <h1 class="mt-0 mb-3 text-white"> Contact Us</h1>

          <p class="mb-0 text-white">
            <a class="text-white" href="#">Home</a> / <span class="text-success"><b>Contact Us</b></span></p>
        </v-col>
      </v-container>
    </v-row>

    <v-container class="mt-10">
      <v-row>
        <v-col
            cols="6">
<h1 class="mt-5 mb-5">Get In Touch</h1>
          <h4 class="mt-5 mb-5">
            <v-icon>mdi-home-map-marker</v-icon> Address :</h4>
          <p>86 Petersham town, New South wales Waedll Steet, Australia PA 6550</p>
          <h4 class="mt-5 mb-5">  <v-icon> mdi-phone</v-icon> Phone :</h4>
          <p>+91 12345-67890, (+91) 123 456 7890</p>
          <h4 class="mt-5 mb-5">  <v-icon>mdi-deskphone</v-icon> Mobile :</h4>
          <p>(+20) 220 145 6589, +91 12345-67890</p>
          <h4 class="mt-5 mb-5">  <v-icon> mdi-email</v-icon> Email :</h4>
          <p>iamosahan@gmail.com, info@gmail.com</p>
          <h4 class="mt-5 mb-5">  <v-icon> mdi-link</v-icon> Website :</h4>
          <p>www.Byteworld.in</p>
        </v-col>
        <v-col
            cols="6" class="  ">
          <h1 class="mt-5 mb-5">Contact Us</h1>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  label="Full Name*"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  label="Phone Number*"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  label="Email Address*"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  label="Message*"
                  required
                  height="50"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn
                  depressed

                  color="error"
              >Send Message
              </v-btn>
            </v-col>
            <!--                login you profile-->

          </v-row>



        </v-col>
      </v-row>
    </v-container>


  </div>
</template>
<script>

export default {
  name: 'About',

}
</script>
