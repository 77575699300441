<template>
    <div class="grocery">
        <v-card elevation="1" class="mt-1 mb-1 pa-5">
            <v-row class="justify-center">
        <span v-for="(category, item) in categoryData.data" :key="item">
          <v-btn small
                 class="mr-2 subtitle-3"
                 color="white"
                 elevation="0"
                 :to="'/category/' + category.slug + '/' + category.id"
                 v-if="item < 5"
          >
            {{ category.name }}
          </v-btn>
        </span>

                <span v-if="categoryData.meta">
                <template v-if="categoryData.meta.total > 5">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="white subtitle-3" elevation="0" v-bind="attrs" v-on="on">
                                More &nbsp;
                                <v-icon class="subtitle-3">mdi-arrow-down-drop-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
              <span v-for="(category, item) in categoryData.data" :key="item">
                <v-list-item link :to="'/category/' + category.slug + '/' + category.id" v-if="item > 5">
                  <v-list-item-content>
                    {{ category.name }}
                  </v-list-item-content>
                </v-list-item>
              </span>
                        </v-list>
                    </v-menu>
                </template>
                    </span>
            </v-row>
        </v-card>

        <v-row class="body-clr mb-10 pb-5">
            <v-container class="mb-5 mt-5 pt-10">
                <PageSkeletonLoader v-if="loading"/>

                <v-row v-else>
                    <v-col lg="3" md="3" sm="12" v-if="subCategoryData.data">
                        <v-tabs vertical class="body-clr pl-2" color="orange" v-model="subCategorySelected"
                        >
                            <v-tab v-for="(category, item) in subCategoryData.data"
                                :key="item"
                                @click="subCategorySelect(category, item)"
                                class="f-clr2 border body-clr justify-start"
                            >
                                {{ category.name }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col
                        class="pa-0"
                        :lg="subCategoryTotal == 0 ? 12 : 9"
                        :md="subCategoryTotal == 0 ? 12 : 9"
                        sm="12"
                    >
                        <v-row class="mt-1">
                            <v-col cols="12" md="9">
                                <h2 v-if="subCategoryData.data">
                                    {{ categoryTitle }}
                                </h2>
                            </v-col>
<!--                            <v-col cols="12" md="3">
                                <v-select
                                    outlined
                                    solo
                                    flatbody-clr
                                    dense 
                                    label="Sort by"
                                    placeholder="Price(Low to High)"
                                ></v-select>
                            </v-col>-->
                        </v-row>

                        <v-row>
                            <v-col
                                v-for="(product, item) in productData.data"
                                :key="item"
                                class="pa-0"
                                cols="3"
                            >
                                <CategoryProductComponent :product="product"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </div>
</template>
<script>
import Category from "@/apis/Category";
import Product from "@/apis/Product";
import PageSkeletonLoader from "@/components/PageSkeletonLoader";
import CategoryProductComponent from "@/components/CategoryProductComponent";

export default {
    name: "Category",

    components: {
        PageSkeletonLoader,
        CategoryProductComponent,
    },

    async mounted() {
        this.category_id = this.$route.params.id;
        this.store_id = this.$store.state.storeInformation.id;
        this.categorySearch.store_id = this.$store.state.storeInformation.id;
        this.subCategorySearch.store_id = this.$store.state.storeInformation.id;
        this.subCategorySearch.category_id = this.$route.params.id;
        this.productSearch.store_id = this.$store.state.storeInformation.id;
        this.productSearch.category_id = this.$route.params.id;

        await this.getCategoryData();
        await this.getCategoryParentData();
        await this.getSubCategoryData();
        await this.getProductData();
    },

    data: () => ({
        loading: true,
        category_id: null,

        categoryData: [],
        categoryTitle: null,
        categoryTotal: 0,

        subCategoryData: [],
        subCategoryItem: null,
        subCategoryTotal: 0,

        productData: [],
        product_id: null,
        productDetail: null,
        productTotal: 0,

        categorySearch: {
            store_id: null,
        },
        categorySelected: 0,

        subCategorySearch: {
            store_id: null,
            category_id: "",
        },
        subCategorySelected: 0,

        productSearch: {
            store_id: null,
            category_id: "",
        },
        productSelected: 0,
    }),

    methods: {
        getCategoryData: async function () {
            this.loading = true;
            return Category.show(this.category_id)
                .then((response) => {
                    this.categoryTitle = response.data.data.name;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getCategoryParentData: async function () {
            this.loading = true;
            return Category.get_parent(this.categorySearch)
                .then((response) => {
                    this.categoryData = response.data;
                    this.loading = false;
                    this.categoryTotal = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getSubCategoryData: async function () {
            this.loading = true;
            return Category.get_child(this.subCategorySearch)
                .then((response) => {
                    this.subCategoryData = response.data;
                    this.loading = false;
                    this.subCategoryTotal = response.data.meta.total;

                    console.log(this.subCategoryData.data[0].id);
                    this.productSearch.category_id = this.subCategoryData.data[0].id;
                    this.categoryTitle = this.subCategoryData.data[0].name;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getProductData: async function () {
            this.loading = true;
            return Product.index(this.productSearch)
                .then((response) => {
                    this.productData = response.data;
                    this.loading = false;
                    this.productTotal = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        subCategorySelect(category, item) {
            this.productSearch.category_id = category.id;
            this.subCategorySelected = item;
            this.getProductData();

            this.categoryTitle = category.name;
        },
    },

    watch: {
        "$route.params.id": async function (val) {
            this.category_id = val;
            this.subCategorySearch.category_id = val;
            this.productSearch.category_id = val;

            await this.getCategoryData();
            await this.getCategoryParentData();
            await this.getSubCategoryData();
            await this.getProductData();
        },
    },
};
</script>
