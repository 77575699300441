import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logo: (localStorage.getItem("storeInformation")) ? JSON.parse(localStorage.getItem("storeInformation")).logo_url : require('../assets/img/EssenMart.png'),
    appName: (localStorage.getItem("storeInformation")) ? JSON.parse(localStorage.getItem("storeInformation")).store_name : "EssenMart",

    // baseUrl: (process.env.NODE_ENV === 'production') ? "https://api.essenmart.com" : "http://localhost:8000",
    baseUrl:"https://api.essenmart.com",

    customerLogin: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")) : null,
    customerId: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).customerId : null,
    name: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).name : null,
    email: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).email : null,
    country_code: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).country_code : null,
    mobile: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).mobile : null,
    image: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).image : null,
    image_file: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).image_file : null,
    type: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).type : null,
    created_at: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).created_at : null,
    token: (localStorage.getItem("customerLogin")) ? JSON.parse(localStorage.getItem("customerLogin")).access_token : null,
    storeInformation: (localStorage.getItem("storeInformation")) ? JSON.parse(localStorage.getItem("storeInformation")) : null,

    location: (localStorage.getItem("location")) ? JSON.parse(localStorage.getItem("location")) : null,
    deliveringAddress: (localStorage.getItem("deliveringAddress")) ? JSON.parse(localStorage.getItem("deliveringAddress")) : null,
    addresses: (localStorage.getItem("addresses")) ? JSON.parse(localStorage.getItem("addresses")) : null,

    cart: (localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : {
      count: 0,
      total: 0,
      chargeableDeliveryCost: 0,
      amtRequiredForFreeDelivery: 0,
      items: {},
      promoInfo: [],
      paymentMode: null,
      step: ["cart"],
      uniqueSkuInCart: 0,
    },

    nextUrl: null,
  },

  getters: {
    loggedIn: state => {
      return state.token !== null
    },
  },
  mutations: {

  },
  actions: {

  },
  modules: {
  }
})
