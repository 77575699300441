<template>
    <div class="body-clr">
        <v-container class="pt-10 pb-10">
            <v-row class="mt-5 mb-5">
                <v-row>
                    <v-col cols="8" class="pr-10 pl-5">
                        <v-card outlined flat class="">
                            <!---phone number---->
                            <v-row class="pt-5 pl-7">
                                <v-col cols="12" md="1">
                                    <v-icon color="green" size="30"
                                    >mdi-checkbox-marked-circle
                                    </v-icon
                                    >
                                </v-col>
                                <v-col cols="12" md="8">
                                    <h5 class="mb-0 h5">Phone Number Verification</h5>
                                    <p class="text-light pt-1 caption">
                                        +{{
                                            this.$store.state.country_code +
                                            " " +
                                            this.$store.state.mobile
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <!--address---->
                            <v-row class="pt-5 pl-7">
                                <v-col cols="12" md="1">
                                    <v-icon color="green" size="30"
                                    >mdi-checkbox-marked-circle
                                    </v-icon
                                    >
                                </v-col>
                                <v-col cols="12" md="8">
                                    <h5 class="mb-0 h5">Delivery Address</h5>
                                    <p class="text-light pt-1 caption">
                                        {{
                                            $store.state.deliveringAddress.address_name
                                        }}&nbsp;:&nbsp;
                                        {{ $store.state.deliveringAddress.formatted_address }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <!--payment---->
                            <v-row class="pt-5 pl-7 mb-5">
                                <v-col cols="12" md="1">
                                    <v-btn class="ma-0" outlined color="green" text fab small>
                                        3
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <h4>Payment</h4>
                                    <v-simple-table class="border pa-5 mt-5">
                                        <tr>
                                            <td class="mb-2">Total Amount</td>
                                            <td class="mb-2">₹ {{ cart.total }}</td>
                                        </tr>

                                        <tr class="pa-5">
                                            <td>
                                                <strong>Amount Payable</strong>
                                                <br/>
                                                <small>(incl. of all taxes)</small>
                                            </td>
                                            <td>₹ {{ cart.total }}</td>
                                        </tr>
                                    </v-simple-table>

                                    <!-- <v-expansion-panels accordion flat class="border mt-4">
                                      <v-expansion-panel>
                                        <v-expansion-panel-header class="pa-5">
                                          <strong> Promo Code & Bank Offers</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="">
                                          <v-row>
                                            <v-col cols="8">
                                              <v-text-field
                                                placeholder="Enter Code"
                                                outlined
                                                dense
                                                color="black"
                                              ></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                              <v-btn color="warning">Apply</v-btn>
                                            </v-col>
                                          </v-row>
                                          <div class="border pa-2 small">
                                            <p class="mb-2">
                                              20% Discount on vegetable & Fruits Apply
                                              Coupon<strong>"F0BEC85"</strong>
                                            </p>
                                            <p class="mb-2">
                                              20% Discount on vegetable & Fruits Apply
                                              Coupon<strong>"F0BEC85"</strong>
                                            </p>
                                          </div>
                                          <div class="mt-3">
                                            <v-simple-table>
                                              <tr class="pa-5">
                                                <td>discount</td>
                                                <td>₹24</td>
                                              </tr>
                                              <tr class="pa-5">
                                                <td>Total Amount pay</td>
                                                <td>₹103</td>
                                              </tr>
                                            </v-simple-table>
                                          </div>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels> -->
                                    <div class="border mt-6">
                                        <v-btn
                                            color="warning"
                                            @click="createOrder()"
                                            block
                                            class="pa-5"
                                        >Pay Now
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <v-col cols="4" class="border">
                        <v-row class="pt-5 pl-5 pr-5">
                            <v-col cols="12" md="9" class="pa-0">
                                <h4>My Cart</h4>
                            </v-col>
                            <v-col cols="12" md="3" class="pa-0">
                                <p>{{ cart.count }} items</p>
                            </v-col>
                        </v-row>

                        <v-list three-line>
                            <v-list-item-group>
                                <template v-for="(item, index) in cart.items">
                                    <v-list-item three-line :key="item.product.product_name">
                                        <v-list-item-icon>
                                            <v-list-item-title>
                                                {{ item.quantity }}
                                            </v-list-item-title>
                                        </v-list-item-icon>

                                        <v-list-item-avatar tile size="60">
                                            <v-img
                                                contain
                                                :src="item.product.thumbnail_file.url"
                                            ></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.product.product_name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.product.product_unit }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="subtitle-2">
                                                ₹ {{ item.product.price }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Orders from "@/apis/Orders";
import OrderProduct from "@/apis/OrderProduct";
import OrderAddress from "@/apis/OrderAddress";

export default {
    name: "Checkout",

    data: () => ({
        cart: {
            count: 0,
            total: 0,
            chargeableDeliveryCost: 0,
            amtRequiredForFreeDelivery: 0,
            items: {},
            promoInfo: [],
            paymentMode: null,
            step: ["cart"],
            uniqueSkuInCart: 0,
        },

        orderForm: {
            customer_id: null,
            store_id: null,
            price: 0,
            type: "website",
        },

        orderProductForm: {
            product_id: [],
            order_id: null,
            store_id: null,
            customer_id: null,
            product_variant_id: [],
            quantity: [],
            price: [],
            sku: [],
        },

        orderAddressForm: {
            order_id: null,
            customer_address_id: null,
            customer_id: null,
            store_id: null,
        },
    }),

    mounted() {
        this.cart = this.$store.state.cart;

        //Order Prefill
        this.orderForm.price = this.$store.state.cart.total;
        this.orderForm.customer_id = this.$store.state.customerId;
        this.orderForm.store_id = this.$store.state.storeInformation.id;

        //Order Product Prefill
        this.orderProductForm.store_id = this.$store.state.storeInformation.id;
        this.orderProductForm.customer_id = this.$store.state.customerId;
        for (var element in this.cart.items) {
            this.orderProductForm.product_id.push(element);
            this.orderProductForm.product_variant_id.push(
                this.$store.state.cart.items[element].product_variant_id
            );
            this.orderProductForm.quantity.push(
                this.$store.state.cart.items[element].quantity
            );
            this.orderProductForm.sku.push(
                this.$store.state.cart.items[element].sku
            );
            this.orderProductForm.price.push(
                this.$store.state.cart.items[element].price 
            );
        }

        //Order Address Prefill
        this.orderAddressForm.store_id = this.$store.state.storeInformation.id;
        this.orderAddressForm.customer_id = this.$store.state.customerId;
        this.orderAddressForm.customer_address_id =
            this.$store.state.deliveringAddress.id;
    },

    methods: {
        createOrder: async function () {
            Orders.store(this.orderForm)
                .then((response) => {
                    this.orderProductForm.order_id = response.data.data.id;
                    OrderProduct.store(this.orderProductForm).then(() => {
                        this.orderAddressForm.order_id = this.orderProductForm.order_id;
                        OrderAddress.store(this.orderAddressForm).then(() => {
                            this.$router.push('/payment/' + this.orderProductForm.order_id);
                        });
                    });
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },
};
</script>
