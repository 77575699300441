<template>
    <div class="body-clr">
        <v-container class="pt-10 pb-10">
            <v-row class="mt-5 mb-5">
                <v-col cols="2"></v-col>
                <v-col cols="8" class="border">
                    <v-row class="white-bg">
                        <v-col cols="4">
                            <ProfileLeftNavComponent/>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="8">
                            <v-data-table
                                :headers="headers"
                                :items="customerAddressData.data"
                                :options.sync="options"
                                :server-items-length="total"
                                :loading="loading"
                            >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title><h4>My Address</h4></v-toolbar-title>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        Total :
                                        {{
                                            customerAddressData.meta
                                                ? customerAddressData.meta.total
                                                : 0
                                        }}
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            small
                                            @click="addAddressDialogFunction"
                                            color="orange"
                                            dark
                                        >
                                            Add New Address
                                        </v-btn>
                                    </v-toolbar>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                small
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Actions
                                            </v-btn>
                                        </template>
                                        <v-list style="cursor: pointer">
                                            <v-list-item link v-on:click="editCustomerAddress(item)">
                                                <v-list-item-title>
                                                    <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                link
                                                v-on:click="deleteCustomerAddress(item)"
                                            >
                                                <v-list-item-title>
                                                    <v-icon small> mdi-trash-can</v-icon>&nbsp; Delete
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn color="orange" dark @click="getCustomerAddressData">
                                        Reset
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>
        </v-container>


    </div>
</template>
<script>
import ProfileLeftNavComponent from "../components/ProfileLeftNavComponent";
import CustomerAddress from "@/apis/CustomerAddress";

export default {
    name: "MyAddress",

    components: {
        ProfileLeftNavComponent,
    },

    mounted() {
        this.form.customer_id = this.$store.state.customerId;
    },

    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: "Type",
                align: "start",
                value: "address_name",
            },
            {text: "Name", sortable: false, value: "contact_name"},
            {
                text: "Address",
                sortable: false,
                value: "formatted_address",
            },
            {text: "Actions", value: "actions", sortable: false},
        ],

        customerAddressData: [],

        form: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            customer_id: "",
        },
        options: {},
        total: 0,

    }),

    methods: {
        getCustomerAddressData() {
            CustomerAddress.index(this.form)
                .then((response) => {
                    this.customerAddressData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        saveCustomerAddressData() {
            CustomerAddress.index({
                customer_id: this.form.customer_id,
                type: "storage",
            })
                .then((response) => {
                    localStorage.setItem("addresses", JSON.stringify(response.data.data));
                    this.$store.state.addresses = JSON.stringify(response.data.data);

                    location.reload();
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getGeoLocate() {
            this.types = "sublocality";
            this.$refs.address.geolocate();
        },

        getAddressData: function (addressData, placeResultData, id) {
            this.addForm.latitude = addressData.latitude;
            this.addForm.longitude = addressData.longitude;
            this.addForm.locality = placeResultData.formatted_address;
        },

        deleteCustomerAddress(item) {
            var con = confirm("Are you sure ?");

            if (con) {
                CustomerAddress.delete(item.id)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "Address Deleted !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getCustomerAddressData();
                        this.saveCustomerAddressData();
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        addAddressDialogFunction(){
            this.$parent.$parent.$parent.addAddressDialog = true;
            this.$parent.$parent.$parent.addAddressForm.customer_id = this.$store.state.customerId;
            this.$parent.$parent.$parent.addAddressForm.store_id = this.$store.state.storeInformation.id;
        }
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getCustomerAddressData();
            },
            deep: true,
        },
    },
};
</script>
