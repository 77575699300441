<template>
  <div>
    <div class="user-profile-header">
      <v-avatar
        :color="this.$store.state.image_file ? '#fafafa' : 'orange'"
        size="150"
      >
        <img
          v-if="this.$store.state.image_file"
          :src="this.$store.state.image_file.url"
        />
        <v-icon v-else size="150" dark> mdi-account-circle </v-icon>
      </v-avatar>

      <h2 class="mb-1 text-secondary">
        <strong>Hi </strong>
        {{ this.$store.state.name ? this.$store.state.name : "Customer" }}
      </h2>
      <p>
        +{{ this.$store.state.country_code + " " + this.$store.state.mobile }}
      </p>
    </div>
    <div class="list-group">
      <router-link
        :to="{ name: 'my-profile' }"
        class="list-group-item list-group-item-action underline-a"
      >
        <v-icon>mdi-account-outline</v-icon>
        My Profile
      </router-link>
      <router-link
        :to="{ name: 'my-address' }"
        class="list-group-item list-group-item-action underline-a"
      >
        <v-icon> mdi-map-marker-circle</v-icon>
        My Address
      </router-link>
      <!-- <router-link
        :to="{ name: 'Wishlist' }"
        class="list-group-item list-group-item-action underline-a"
      >
        <v-icon> mdi-heart-outline</v-icon>
        Wishlist
      </router-link> -->
      <router-link
        :to="{ name: 'my-order' }"
        class="list-group-item list-group-item-action underline-a"
      >
        <v-icon> mdi-format-list-bulleted</v-icon>
        Order List
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  name: "ProfileLeftNavComponent",
};
</script>
