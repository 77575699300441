<template>
    <div class="body-clr">
        <v-container class="pt-10 pb-10">
            <v-row class="mt-5 mb-5">
                <v-col cols="2"></v-col>
                <v-col cols="8" class="border">
                    <v-row class="white-bg">
                        <v-col cols="4">
                            <ProfileLeftNavComponent/>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="8" class="pr-7 pl-7">
                            <h2>My Profile</h2>
                            <v-form
                                v-model="valid"
                                @submit.prevent="submitCustomerForm"
                                ref="form"
                                class="mt-10"
                            >
                                <v-row>
                                    <v-col cols="6" sm="12" md="6" lg="6">
                                        <v-text-field
                                            outlined
                                            v-model="form.name"
                                            :rules="rules.name"
                                            label="Name"
                                            placeholder="Enter Your Name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="12" md="6" lg="6">
                                        <v-text-field
                                            outlined
                                            v-model="form.email"
                                            :rules="rules.email"
                                            label="Email Address"
                                            placeholder="Enter Email Address"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="12" md="6" lg="6">
                                        <v-file-input
                                            outlined
                                            :rules="rules.image"
                                            v-model="files.image"
                                            accept="image/png, image/jpeg, image/bmp, image/webp"
                                            placeholder="Choose Profile Image"
                                            prepend-icon="mdi-camera"
                                            label="Profile Image"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="6" sm="12" md="6" lg="6">
                                        <VuePhoneNumberInput
                                            v-model="mobile"
                                            valid-color="#666666"
                                            default-country-code="IN"
                                            required
                                            v-on:update="getMobileData"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="mt-5">
                                    <v-col cols="12" align-self="center">
                                        <v-btn
                                            :loading="loading"
                                            :disabled="loading"
                                            type="submit"
                                            color="success"
                                        >
                                            Save Change
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Customer from "@/apis/Customer";
import ProfileLeftNavComponent from "../components/ProfileLeftNavComponent";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    name: "MyProfile",

    components: {
        ProfileLeftNavComponent,
        VuePhoneNumberInput,
    },

    mounted() {
        this.form.id = this.$store.state.customerId;
        this.form.store_id = this.$store.state.storeInformation.id;
        this.form.name = this.$store.state.name;
        this.form.email = this.$store.state.email;
        this.form.mobile = this.$store.state.mobile;
        this.mobile = this.$store.state.mobile.toString();
        this.form.country_code = this.$store.state.country_code;
    },

    data: () => ({
        valid: false,
        loading: false,
        mobile: "",
        form: {
            id: "",
            name: "",
            email: "",
            mobile: "",
            country_code: "",
            store_id: "",
        },
        files: {
            image: null,
        },
        rules: {
            name: [(v) => !!v || "Profile Name is required"],
            email: [
                (v) => !!v || "Email is required",
                (v) => /.+@.+/.test(v) || "E-mail must be valid",
            ],
            mobile: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            country_code: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            image: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Profile Image size should be less than 2 MB!",
            ],
        },
    }),

    methods: {
        getMobileData(val) {
            this.form.country_code = val.countryCallingCode;
            this.form.mobile = val.nationalNumber;
            this.mobileRule = val.isValid;
        },

        submitCustomerForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.form) {
                    fd.append(element, this.form[element]);
                }

                for (var fileElement in this.files) {
                    fd.append(fileElement, this.files[fileElement]);
                }

                Customer.update(fd, this.form.id)
                    .then((response) => {
                        var localItem = {
                            access_token: this.$store.state.token,
                            customerId: response.data.data.id,
                            name: response.data.data.name,
                            email: response.data.data.email,
                            country_code: response.data.data.country_code,
                            mobile: response.data.data.mobile,
                            image: response.data.data.image,
                            image_file: response.data.data.image_file,
                            type: response.data.data.type,
                            created_at: response.data.data.created_at,
                        };

                        localStorage.setItem("customerLogin", JSON.stringify(localItem));

                        this.$store.state.customerId = response.data.data.id;
                        this.$store.state.name = response.data.data.name;
                        this.$store.state.email = response.data.data.email;
                        this.$store.state.country_code = response.data.data.country_code;
                        this.$store.state.mobile = response.data.data.mobile;
                        this.$store.state.image = response.data.data.image;
                        this.$store.state.image_file = response.data.data.image_file;
                        this.$store.state.type = response.data.data.type;
                        this.$store.state.created_at = response.data.data.created_at;
                        this.$store.state.customerLogin = JSON.stringify(localItem);

                        this.loading = false;

                        this.$notify({
                            title: "Success",
                            text: "Profile Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },
};
</script>
