<template>
    <div class="body-clr">
        <v-container>
            <div class="text-center mt-8 mb-10">
                <v-icon size="100" color="success" dark>mdi-check-circle-outline</v-icon>
                <h1 class="t-font mb-0">Order Confirm </h1>

                <p class="t-small-p mb-lg-4">Order id: {{ orderData.order_code }}</p>
                <v-btn color="warning" dark @click="$router.push({ name: 'my-order' })">view order status</v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>

import Orders from "@/apis/Orders";

export default {
    name: "Thanks",

    data: () => ({
        order_id: "",
        orderData: {},
        cart: {
            count: 0,
            total: 0,
            chargeableDeliveryCost: 0,
            amtRequiredForFreeDelivery: 0,
            items: {},
            promoInfo: [],
            paymentMode: null,
            step: ["cart"],
            uniqueSkuInCart: 0,
        },
    }),

    mounted() {
        this.order_id = this.$route.params.id;
        this.getOrder();

        localStorage.setItem("cart", JSON.stringify(this.cart));
        this.$store.state.cart = this.cart;
    },

    methods: {
        getOrder: function () {
            Orders.show(this.order_id)
                .then((response) => {
                    this.orderData = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },
};
</script>
