import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/category", {
            params: form
        });
    },
    
    async get_parent(form) {
        return Api.get("/category-parent", {
            params: form
        });
    },

    async get_child(form) {
        return Api.get("/category-child", {
            params: form
        });
    },
    
    async get_tree(form) {
        return Api.get("/category-tree", {
            params: form
        });
    },
    
    async get_category_product(form) {
        return Api.get("/category-product", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/category/" + id, form);
    },

    async show(id) {
        return Api.post("/category/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/category/" + id, form);
    },

    async changeStatus(id) {
        return Api.post("/category-status/" + id);
    },

    async delete(id) {
        return Api.delete("/category/" + id);
    },
};
