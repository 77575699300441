<template>
  <div class="mt-3">
    <v-container v-show="loading">
      <PageSkeletonLoader />
    </v-container>

    <v-container v-show="!loading">
      <v-carousel
        class="b-img"
        cycle
        height="250"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          width="100%"
          contain
          v-for="(banner, i) in storeBanner"
          :key="i"
          :src="banner.url"
          height="auto"
        >
        </v-carousel-item>
      </v-carousel>
    </v-container>

    <!--    small slider-->
    <section v-show="!loading" class="small-slider pa-5">
      <v-container>
        <v-row>
          <v-col
            style="cursor: pointer"
            cols="12"
            md="1"
            class="ma-2"
            v-for="(category, i) in categoryData"
            :key="i"
            @click="
              $router.push('/category/' + category.slug + '/' + category.id)
            "
          >
            <v-img height="90" contain :src="category.banner_file.url"></v-img>
            <span class="text-center">
              <h5>{{ category.name }}</h5>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--    small slider end-->

    <!--    Products View-->
    <section
      v-show="!loading"
      v-for="(category, i) in categoryProductData"
      :key="i"
    >
      <v-container class="mt-5">
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <v-row>
              <h3 class="text-left font-weight-medium">{{ category.name }}</h3>

              <v-spacer></v-spacer>
              <v-btn
                color="orange"
                dark
                x-small
                elevation="0"
                :to="'/category/' + category.slug + '/' + category.id"
              >
                see more <v-icon>mdi-arrow-right-thin</v-icon>
              </v-btn>
            </v-row>
          </v-col>

          <v-slide-group class="pa-4" show-arrows>
            <v-slide-item
              v-for="(product, i) in category.product"
              :key="i"
              v-slot:default="{ active, toggle }"
            >
              <ProductComponent :product="product" />
            </v-slide-item>
          </v-slide-group>
        </v-row>
      </v-container>
    </section>
    <!--    Best Offers View end-->
    <section v-show="!loading">
      <v-container class="container">
        <v-row class="mb-10">
          <div class="text-justify ma-5">
            <h2>Online Grocery Store</h2>
            <p>
              Did you ever imagine that the freshest of fruits and vegetables,
              top quality pulses and food grains, dairy products and hundreds of
              branded items could be handpicked and delivered to your home, all
              at the click of a button? India’s first comprehensive online
              megastore, , brings a whopping 20000+ products with more than 1000
              brands, to over 4 million happy customers. From household cleaning
              products to beauty and makeup, Grocery has everything you need for
              your daily needs.Grocery is convenience personified We’ve taken
              away all the stress associated with shopping for daily essentials,
              and you can now order all your household products and even buy
              groceries online without travelling long distances or standing in
              serpentine queues. Add to this the convenience of finding all your
              requirements at one single source, along with great savings, and
              you will realize that Grocery- India’s largest online supermarket,
              has revolutionized the way India shops for groceries. Online
              grocery shopping has never been easier. Need things fresh? Whether
              it’s fruits and vegetables or dairy and meat, we have this covered
              as well! Get fresh eggs, meat, fish and more online at your
              convenience...
              <a href=""> Read More</a>
            </p>
          </div>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import Category from "@/apis/Category";
import ProductComponent from "@/components/ProductComponent";
import PageSkeletonLoader from "@/components/PageSkeletonLoader";

export default {
  name: "Home",

  components: {
    ProductComponent,
    PageSkeletonLoader,
  },

  async mounted() {
    this.storeBanner.push(this.$store.state.storeInformation.banner_file);

    this.form.store_id = this.$store.state.storeInformation.id;

    await this.getCategoryData();
    await this.getCategoryProductData();
  },

  data() {
    return {
      loading: false,
      storeBanner: [],
      categoryData: [],
      categoryProductData: [],
      form: {
        store_id: null,
      },
    };
  },

  methods: {
    getCategoryData: async function () {
      this.loading = true;
      return Category.get_tree(this.form)
        .then((response) => {
          this.categoryData = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getCategoryProductData: async function () {
      this.loading = true;
      return Category.get_category_product(this.form)
        .then((response) => {
          this.categoryProductData = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

