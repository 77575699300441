<template>
    <div class="body-clr">
        <v-container class="pt-10 pb-10">
            <v-row class="mt-5 mb-5"></v-row>
        </v-container>
    </div>
</template>

<script>
import RazorpayPayment from "../apis/RazorpayPayment";

export default {
    name: "Payment",

    data: () => ({
        orderId: "",
        orderDetail: {},
    }),

    mounted() {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        document.head.appendChild(recaptchaScript);

        this.orderId = this.$route.params.id;

        this.createOrderId();
    },

    methods: {
        createOrderId() {
            RazorpayPayment.create_order({
                order_id: this.orderId,
            }).then((response) => {
                this.orderDetail = response.data.data;

                var rzp1 = new Razorpay(this.orderDetail);

                rzp1.open();
                e.preventDefault();
            })
                .catch((error) => {
                    if (error.response.status == 400) {
                        this.$notify({
                            title: "Error",
                            text: error.response.data.message,
                            type: "error",
                            duration: 10000,
                        });
                        this.$router.push({name:"home"});
                    }
                });

        },
    },
};
</script>
