import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
require("./assets/css/style.css")

Vue.config.productionTip = false
Vue.use(Notifications);

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyDHvjFk37MOLVvM4Sxev9Kx9NrKusGEVok', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
  libraries: "places"
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')



