<template>
  <div class="about">
    <v-row class="bg-dark">
      <v-container class="mt-5 mb-5">
        <v-col class="text-center"
               cols="12">
          <h1 class="mt-0 mb-3 text-white"> About Us</h1>

          <p class="mb-0 text-white">
            <a class="text-white" href="#">Home</a> / <span class="text-success"><b>About Us</b></span></p>
        </v-col>
      </v-container>
    </v-row>

    <v-container class="mt-10">
      <v-row>
        <v-col
            cols="6">


          <img src="../assets/img/about.jpg"/></v-col>
        <v-col
            cols="6" class=" pr-5 pt-10">

          <h1 class="mt-5 mb-5 text-secondary h1-38">Save more with GO! We give you the lowest prices on all your
            grocery needs.</h1>
          <h2 class="mt-5 mb-4">Our Vision</h2>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when
            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
            letters, as opposed to using 'Content here,</p>
          <h2 class="mt-5 mb-4">Our Goal</h2>
          <p>When looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, Lorem Ipsum has been the industry's standard
            dummy text ever since.</p>


        </v-col>
      </v-row>
    </v-container>

    <!--    second session-->
    <v-row class="body-clr">
      <v-container class="mt-5 mb-10 pt-5 pb-5">
        <h1 class="text-center">What We Provide?</h1>
        <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        <v-row>
          <v-col cols="4">
            <v-icon class="mt-4 mdi-48" x-large>mdi-shopping</v-icon>
            <h2 class="mt-3 mb-3 text-secondary">Best Prices &amp; Offers</h2>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour.</p>
          </v-col>
          <v-col cols="4">
            <v-icon class="mt-4 mdi-48" x-large>mdi-earth</v-icon>
            <h2 class="mt-3 mb-3 text-secondary">Wide Assortment</h2>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour.</p>
          </v-col>
          <v-col cols="4">
            <v-icon class="mt-4  mdi-48" x-large>mdi-refresh</v-icon>
            <h2 class="mt-3 mb-3 text-secondary">Easy Returns</h2>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour.</p>
          </v-col>
        </v-row>


      </v-container>
    </v-row>
  </div>
</template>
<script>

export default {
  name: 'About',

}
</script>
