<template>
    <div class="grocery">
        <v-row class="body-clr mb-10 pb-5">
            <v-container class="mb-5 mt-5 pt-10">
                <v-row>
                    <v-col class="" cols="6">
                        <v-carousel
                            class="pl-5 pr-5"
                            height="460"
                            hide-delimiter-background
                            show-arrows-on-hover
                            cycle
                        >
                            <v-carousel-item
                                v-for="(item, i) in productData.product_image"
                                :key="i"
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                            >
                                <v-img :src="item.image_file.url" contain height="450" ></v-img>
                            </v-carousel-item>
                        </v-carousel>
                        <div class="mt-5">
                            <h3 class="mb-2">Product Details</h3>
                            <div v-html="productData.description"></div>
                        </div>
                    </v-col>

                    <v-col class="" cols="6">
                        <div class="">
                            <v-row class="mb-5">
                                <v-col cols="12" md="8">
                                    <p class="mb-1">{{ productData.product_name }}</p>
                                    <p class="text-light mt-1 mb-1">
                                        {{
                                            (productData.variant == 'Y') ? variantInformation.attribute_value.name : productData.product_unit
                                        }}
                                    </p>
                                    <h4>₹ {{ (productData.variant == 'Y') ? variantInformation.price : productData.price }}</h4>
                                    <!-- <strike><small class="text-light">₹ {{ productData.price }}</small> </strike> -->
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-row class="pb-0 mb-0">
                                        <v-text-field
                                            readonly
                                            v-model="quantity"
                                            solo
                                            v-if="quantity > 0"
                                            class="pb-0 mb-0 centered-input"
                                        >
                                            <template v-slot:prepend>
                                                <v-btn
                                                    @click="removeFromCart(productData)"
                                                    dark
                                                    color="orange"
                                                    class="mr-0"
                                                    x-small
                                                    elevation="0"
                                                >
                                                    <v-icon>mdi-minus</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:append-outer>
                                                <v-btn
                                                    @click="addToCart(productData)"
                                                    dark
                                                    color="orange"
                                                    class="ml-0"
                                                    x-small
                                                    elevation="0"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>

                                        <v-btn
                                            small
                                            dark
                                            class="mb-5 centered-input"
                                            color="orange"
                                            v-else
                                            @click="addToCart(productData)"
                                        >
                                            <v-icon>mdi-cart</v-icon>&nbsp;Add to Cart
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <div class="mb-10 mt-5" v-if="productData.variant == 'N'">
                                <p>Unit</p>
                                <v-btn outlined color="green" text>
                                    {{ productData.product_unit }}
                                </v-btn>
                            </div>

                            <div class="mb-10 mt-5" v-else>
                                <v-row>
                                    <v-radio-group v-model="variantRadio" row>
                                        <v-card v-for="(variant,key) in productData.product_variant"
                                                :key="key"
                                                class="mx-3"
                                                max-width="150"
                                        >
                                            <v-card-title>
                                                <v-radio
                                                    :label="variant.attribute_value.name"
                                                    :value="variant.id"
                                                    @click="variantChanged(variant)"
                                                ></v-radio>
                                            </v-card-title>
                                            <v-card-text>
                                                <div class="text-subtitle-1">
                                                    {{ variant.price }}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-radio-group>
                                </v-row>
                            </div>

                            <h4 class="mt-8 pt-10">Why shop from Essenmart?</h4>
                            <v-row class="mt-5">
                                <v-col cols="12" md="2" class="pa-1">
                                    <v-img
                                        contain
                                        src="../assets/img/express-delivery-icon-93fce76.png"
                                        height="55"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" md="8" class="pa-1">
                                    <p class="small">
                                        10 minute grocery now<br/>
                                        Get your order delivered to your doorstep at the earliest
                                        from dark stores near you.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="2" class="pa-1">
                                    <v-img
                                        contain
                                        src="../assets/img/footer-best-price-icon-90b5bd7.png"
                                        height="55"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" md="8" class="pa-1">
                                    <p class="small">
                                        Best Prices & Offers<br/>
                                        Cheaper prices than your local supermarket, great cashback
                                        offers to top it off.
                                    </p>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="2" class="pa-1">
                                    <v-img
                                        contain
                                        src="../assets/img/footer-genuine-products-icon-d2756ce.png"
                                        height="55"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" md="8" class="pa-1">
                                    <p class="small">
                                        Wide Assortment<br/>
                                        Choose from 5000+ products across food, personal care,
                                        household & other categories.
                                    </p>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="2" class="pa-1">
                                    <v-img
                                        contain
                                        src="../assets/img/footer-easy-returns-icon-02b777e.png"
                                        height="55"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12" md="8" class="pa-1">
                                    <p class="small">
                                        Easy Returns<br/>
                                        Not satisfied with a product? Return it at the doorstep &
                                        get a refund within hours
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>

        <!--  <v-container class="mt-5 mb-10">
          <v-row>
            <v-col lg="12" md="12" sm="12">
              <h2 class="text-center">Best Offers View</h2>
            </v-col>

            <v-slide-group class="pa-4" show-arrows>
              <v-slide-item v-for="n in 15" :key="n" v-slot="{ active, toggle }">
                <v-card class="ma-1" width="250" @click="toggle">
                  <v-chip class="mt-4 ml-4" outlined color="green">15% OFF </v-chip>

                  <a href="/grocery">
                    <v-img
                      height="150"
                      max-width="250"
                      src="../../public/assets/img/item/1.jpg"
                    ></v-img>
                  </a>

                  <v-card-title>Product Title Here</v-card-title>
                  <v-card-text>
                    <v-row align="center" class="mx-0">
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                      ></v-rating>

                      <div class="grey--text ms-4">4.5 (413)</div>
                    </v-row>

                    <div class="my-4 text-subtitle-1">Available in - 500 gm</div>
                    <v-row align="center" class="mx-0">
                      <h3 class="mr-5"><b>Rs- 45</b></h3>
                      <v-card-actions>
                        <v-btn
                          class="pr-4 pl-4 pt-4 pb-4"
                          rounded
                          x-small
                          color="warning"
                          dark
                          onclick="addtocard"
                        >
                          <v-icon color="white lighten-1"> mdi-cart </v-icon>
                          Add To Cart
                        </v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-container> -->
    </div>
</template>
<script>
import Product from "@/apis/Product";

export default {
    name: "ProductDetail",

    created() {
        if (this.$route.params.id) {
            this.product_id = this.$route.params.id;
            this.getProductDetail();
        }

        if (this.$store.state.cart) {
            if (this.containsKey(this.$store.state.cart.items, this.product_id)) {
                this.quantity = this.$store.state.cart.items[this.product_id].quantity;
            }
        }
    },

    data: () => ({
        productData: [],
        loading: true,
        product_id: "",
        quantity: 0,
        variantRadio: "",
        variantInformation: {
            price:0,
        }
    }),

    methods: {
        getProductDetail: function () {
            Product.show(this.product_id)
                .then((response) => {
                    this.productData = response.data.data;
                    this.variantRadio = this.productData.variant_id;
                    console.log(this.productData.single_product_variant);
                    this.variantInformation = this.productData.single_product_variant;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        addToCart(pro) {
            var obj = {
                product: pro,
                sku: "",
                product_variant_id: null,
                price: 0,
                quantity: 0,
            };

            if (this.$store.state.cart) {
                if (!this.containsKey(this.$store.state.cart.items, pro.id)) {
                    this.$set(this.$store.state.cart.items, pro.id, obj);
                    this.$store.state.cart.uniqueSkuInCart++;
                }
            } else {
                this.$set(this.$store.state.cart.items, pro.id, obj);
                this.$store.state.cart.uniqueSkuInCart++;
            }

            this.$store.state.cart.items[pro.id].quantity++;

            var price = pro.price;
            this.$store.state.cart.items[pro.id].sku = pro.sku;
            this.$store.state.cart.items[pro.id].price = pro.price;
            if(this.variantInformation) {
                this.$store.state.cart.items[pro.id].product_variant_id = this.variantInformation.id;
                this.$store.state.cart.items[pro.id].sku = this.variantInformation.sku;
                this.$store.state.cart.items[pro.id].price = this.variantInformation.price;
                price = this.variantInformation.price;
            }
            this.quantity++;
            this.$store.state.cart.count++;
            this.$store.state.cart.total += parseInt(price);

            localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
        },

        removeFromCart(pro) {
            if (this.$store.state.cart) {
                if (!this.containsKey(this.$store.state.cart.items, pro.id))
                    return false;
            } else return false;

            this.$store.state.cart.items[pro.id].quantity--;
            this.quantity--;
            this.$store.state.cart.count--;
            this.$store.state.cart.total -= parseInt(this.$store.state.cart.items[pro.id].price);

            if (this.quantity == 0) {
                this.$delete(this.$store.state.cart.items, pro.id);
                this.$store.state.cart.uniqueSkuInCart--;
            }

            localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
        },

        containsKey(obj, key) {
            for (var keyObj in obj) {
                if (keyObj == key) return true;
            }
            return false;
        },

        variantChanged(variant){
            this.variantInformation = variant;
        }
    },

    watch: {
        "$route.params.id": function (val) {
            // console.log("Hello");
            this.product_id = this.$route.params.id;
            this.getProductDetail();

            if (this.$store.state.cart) {
                if (this.containsKey(this.$store.state.cart.items, this.product_id)) {
                    this.quantity = this.$store.state.cart.items[this.product_id].quantity;
                }
            }
        },
    }
};
</script>
