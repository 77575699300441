import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from "../views/Category";
import About from "../views/About";
import Contactus from "../views/Contactus";
import MyProfile from "../views/MyProfile";
import MyAddress from "../views/MyAddress";
import Wishlist from "../views/Wishlist";
import MyOrder from "../views/MyOrder";
import Checkout from "../views/Checkout";
import PaymentView from "../views/PaymentView";
import ProductDetail from "../views/ProductDetail";
import Thanks from "@/views/Thanks";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'about',
    component: About

  },
  {
    path: '/category/:name/:id',
    name: 'category',
    component: Category
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: Contactus
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: MyProfile
  },
  {
    path: '/my-address',
    name: 'my-address',
    component: MyAddress
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: Wishlist
  },
  {
    path: '/my-order',
    name: 'my-order',
    component: MyOrder
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/thanks/:id',
    name: 'thanks',
    component: Thanks
  },
  {
    path: '/payment/:id',
    name: 'payment-view',
    component: PaymentView
  },
  {
    path: '/product/:name/:id',
    name: 'product',
    component: ProductDetail
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
