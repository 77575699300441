<template>
  <v-card flat outlined class="pa-1">
    <!-- <v-chip class="mt-4 ml-4" color="green" label dark small
                    >15% OFF
                  </v-chip> -->

    <v-img
      contain
      class="pa-5"
      height="150"
      max-width="260"
      @click="
        $router.push('/product/' + product.product_slug + '/' + product.id)
      "
      :src="product.thumbnail_file.url"
    ></v-img>
    <v-row class="ml-1 mr-1 mt-n4 justify-center">
      <v-card-actions>
        <v-row class="pb-0 mb-0">
          <v-text-field
          readonly
            v-model="quantity"
            solo
            v-if="quantity > 0"
            class="pb-0 mb-0 centered-input"
          >
            <template v-slot:prepend>
              <v-btn
                @click="removeFromCart(product)"
                dark
                color="orange"
                class="mr-0"
                x-small
                elevation="0"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <template v-slot:append-outer>
              <v-btn
                @click="addToCart(product)"
                dark
                color="orange"
                class="ml-0"
                x-small
                elevation="0"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-btn
            small
            dark
            class="mb-5 centered-input"
            color="orange"
            v-else
            @click="addToCart(product)"
          >
            <v-icon>mdi-cart</v-icon>&nbsp;Add to Cart
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-row>

    <v-card-title
      @click="
        $router.push('/product/' + product.product_slug + '/' + product.id)
      "
      class="pt-0 mt-0 pb-0"
    >
      {{ product.product_name }}
    </v-card-title>
    <v-card-text>
      <div class="my-4 text-subtitle-1">
        {{ product.product_unit }}
      </div>
      <v-row align="center" class="mx-0">
        <h3>Rs {{ product.price }}</h3>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  name: "CategoryProductComponent",

  props: ["product"],

  data: () => ({
    quantity: 0,
  }),

  mounted() {
    if (this.$store.state.cart) {
      if (this.containsKey(this.$store.state.cart.items, this.product.id)) {
        this.quantity = this.$store.state.cart.items[this.product.id].quantity;
      }
    }
  },

  methods: {
    addToCart(pro) {
      var obj = {
        product: pro,
        quantity: 0,
      };

      if (this.$store.state.cart) {
        if (!this.containsKey(this.$store.state.cart.items, pro.id)) {
          this.$set(this.$store.state.cart.items, pro.id, obj);
          this.$store.state.cart.uniqueSkuInCart++;
        }
      } else {
        this.$set(this.$store.state.cart.items, pro.id, obj);
        this.$store.state.cart.uniqueSkuInCart++;
      }

      this.$store.state.cart.items[pro.id].quantity++;
      this.quantity++;
      this.$store.state.cart.count++;
      this.$store.state.cart.total += parseInt(pro.price);

      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
    },

    removeFromCart(pro) {
      if (this.$store.state.cart) {
        if (!this.containsKey(this.$store.state.cart.items, pro.id))
          return false;
      } else return false;

      this.$store.state.cart.items[pro.id].quantity--;
      this.quantity--;
      this.$store.state.cart.count--;
      this.$store.state.cart.total -= parseInt(pro.price);

      if (this.quantity == 0) {
        this.$delete(this.$store.state.cart.items, pro.id);
        this.$store.state.cart.uniqueSkuInCart--;
      }

      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
    },

    containsKey(obj, key) {
      for (var keyObj in obj) {
        if (keyObj == key) return true;
      }
      return false;
    },
  },
};
</script>
